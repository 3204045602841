import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export default function SearchToolTip() {
    return (
        <div>
            <Tooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit" variant='caption'>Basic Query Terms</Typography>
                        <li>A single term is a single word such as <i>test</i> or <i>hello</i>.</li>
                        <li>Enclose phrases in double quotes to search for an exact match. For example, <i>"red apple"</i> will find documents containing that specific phrase.
                            Without enclosing, documents containing either <i>red</i> or <i>apple</i> will be retrieved.</li><br/>
                        <Typography color="inherit" variant='caption'>Field Specific Search</Typography>
                        <li>You can add the "field_name:" in front of the search keyword to search for only the specified field.</li>
                        <li>For example, <i>title:test</i> to match documents that has <i>test</i> in their title; or <i>content:test</i> to match in the main content.</li><br/>
                        <Typography color="inherit" variant='caption'>Wildcard Matching</Typography>
                        <li>Use "<b>*</b>" for wildcard matching. For example, <i>app*</i> will match <i>apple, application,</i> etc.</li>
                        <li>Logical operators (AND, OR, NOT) are supported.</li><br/>
                        <Typography color="inherit" variant='caption'>Fuzzy Searches</Typography>
                        <li>Use "<b>~</b>" to perform fuzzy searches. For example, <i>roam~</i> will match <i>roam, foam</i>, and similar terms with a small edit distance.</li>
                        <li>Use "<b>~</b>" followed by a number to specify the maximum edit distance between terms. For example, <i>cook~1</i> will match <i>cooks, book, hook</i> but not <i>books</i> or <i>hooks</i>.</li><br/>
                    </React.Fragment>
                }
                placement='bottom-end'
            >
                <Typography sx={{textDecoration: "underline"}}>Need help?</Typography>
            </Tooltip>
        </div>
    );
}