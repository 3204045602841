import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DaiCheckBox from './DaiCheckBox';
import { FormGroup } from '@mui/material';

export const fileTypeList = [{
    name: "html",
    label: "Web Page" 
}, {
    name: "pdf",
    label: "PDF"
}, {
    name: "docx",
    label: "Word"
}, {
    name: "pptx",
    label: "PowerPoint"
}, {
    name: "xslx",
    label: "Excel"
}]

export default function FileTypeCheckBoxesGroup({states, handleChange}) {
    return (
    <FormControl sx={{'textAlign': 'left', 'paddingRight': '1.8rem'}}>
        <FormLabel id="file-types-check-box-group-label" sx={{color: '#133369', '&.Mui-focused': {color: "#133369"}}}>File Type</FormLabel>
        <FormGroup>
            <FormControlLabel checked={states.all} name='all'
                control={<DaiCheckBox />} 
                label="All" onChange={e => handleChange(e)}/>

            {fileTypeList.map((type) => (
            <FormControlLabel checked={states[type.name]} name={type.name}
                control={<DaiCheckBox />}
                label={type.label} 
                onChange={e => handleChange(e)} />
            ))}


        </FormGroup>

    </FormControl>
    );
}