import React, { Fragment, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { Container } from '@mui/system';
import { Button, Card, Grid, IconButton, Input, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LOGIN_API =  window.location.origin + '/api/v1/ldap/login';

export default function Login({ isAuth, setIsAuth }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);


    // const handleSubmit = async () => {
    const handleSubmit = async event => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        let res = await fetch(LOGIN_API, {
            method: "POST",
            credentials: 'include',
            body: formData
        });
        let resJson = res.json();

        if (document.cookie.indexOf('auth_token') > -1) {
            setLoginError(null);
            setIsAuth(true);
            localStorage.setItem("username", username);
            window.location.reload();
        }
        else if (resJson.message) {
            setLoginError(resJson.message);
        }
        else {
            setLoginError('Something went wrong!');
        }
    };

    return (
        <Card>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div style={{ marginTop: '10%' }}>
                    <Input placeholder="Username" name="username"
                        value={username} variant="outlined" required
                        onChange={(e) => setUsername(e.target.value)}>
                    </Input>
                </div>
                <div style={{ marginTop: '10%' }}>
                    <Input type='password' placeholder="Password" name="password"
                        value={password} variant="outlined" required
                        onChange={(e) => setPassword(e.target.value)}>
                    </Input>
                </div>
                <div style={{ marginTop: '10%'}}>
                    <Typography variant='subtitle2' color='error'>
                        <b>{loginError}</b>
                    </Typography>
                </div>
                <div style={{ marginTop: '10%' }}>
                    <Button type='submit' name="loginSubmit">
                        Login
                    </Button>
                </div>
            </form>
        </Card>

    );
}