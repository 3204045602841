import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Card, CardActions, CardContent, Link, Typography } from '@mui/material';
import parseHTML from 'html-react-parser';
import { createTheme, ThemeProvider } from "@mui/material/styles";

// custom theme for the Link style
const LinkTheme = createTheme({
  components: {
      MuiLink: {
          styleOverrides: {
              root: {
                  fontWeight: "bold",
                  color: "#133369",
                  '&:hover': {
                    color: "#54c4c9",
                  },
              },
          },
      },
  },
});

function getDisplayItems(doc) {
  // Return appropriate title, description fields and the logo based on the source website
  if (doc.host[0].includes('ticaret.gov')) {
    return 'ticaretbkn_logo.png';
  }
  else if (doc.host[0].includes('resmigazete.gov')) {
    return 'resmigzt_logo.png';
  }
  else if (doc.host[0].includes('sanayi.gov')) {
    return 'sanayibkn_logo.jpeg';
  }
  else if (doc.host[0].includes('yatirimadestek.gov')) {
    return 'yatirimadestek_logo.jpeg';
  }
  else if (doc.host[0].includes('itkib.org')) {
    return 'itkib_logo.png';
  }
  else if (doc.host[0].includes('tubitak.gov.tr')) {
    return 'tubitak_logo.jpg';
  }
  else if (doc.host[0].includes('www.csb.gov.tr')) {
    return 'csb_logo.png';
  }
  else if (doc.host[0].includes('www.csgb.gov.tr')) {
    return 'csgb_logo.png';
  }
  else if (doc.host[0].includes('www.mevzuat.gov.tr')) {
    return 'mevzuat_logo.jpg';
  }
  else if (doc.host[0].includes('taxation-customs.ec.europa.eu')) {
    return 'europeancommission_logo.png';
  }
  else if (doc.host[0].includes('www.efrag.org')) {
    return 'efrag_logo.jpg';
  }
  else if (doc.host[0].includes('eur-lex.europa.eu')) {
    return 'eurlex_logo.png';
  }
  else if (doc.host[0].includes('commission.europa.eu')) {
    return 'europeancommission_logo.png';
  }
}

const SearchResult = ({ doc, highlight, isMLT, handleMoreLikeThis }) => {

  const [expanded, setExpanded] = useState(false);
  const date = new Date(doc.date[0]);
  const lastModified = "lastModified" in doc ? new Date(doc.lastModified[0]).toLocaleDateString('tr-TR') : "Not available"
  // If no title available at all, use file name
  let title = doc.title ? doc.title[0] : doc.file_name[0];
  let description = (String(doc.content) === "undefined" ? parseHTML("<i>No Content preview available</i>") : String(doc.content).length > 2000 ? String(doc.content).substring(0, 2000) + "..." : String(doc.content));
  if (highlight && !isMLT) {
    // If a highlight snippet is available, use it instead of the actual title
    if (highlight.title.length > 0) {
      title = parseHTML(highlight.title[0]);
    }
    // If a highlight snippet is available, use it instead of the actual content
    if (highlight.content.length > 0) {
      description = parseHTML(highlight.content[0]);
    }

  }
  const logo_source = getDisplayItems(doc);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent sx={{padding: '16px 16px 0px 16px'}}>
        <div className='result-top'>
          <div className='result-header'>
            <div className='result-title'>
              <ThemeProvider theme={LinkTheme}>
                <Link href={doc.url[0]} variant="p" target="_blank" rel="noopener noreferrer" sx={{ color: '#133369' }}>
                  {title}
                </Link>
              </ThemeProvider>
            </div>
            <div className='result-meta'>
              <Typography variant='caption'>
                <b>Creation Date: </b> {date.toLocaleDateString('tr-TR')}
              </Typography>
              <Typography variant='caption'>
                <b>Last Modified: </b> {lastModified}
              </Typography>
            </div>
            {/* <div className='result-meta'>
              <Typography variant='caption'>

              </Typography>
            </div> */}
          </div>
          <div className='result-image'>
            <img src={logo_source} alt='Source Logo'></img>
          </div>
        </div>
        <div className='result-content'>
          <Typography variant="caption" component="div"
            // change the size of the text based on the state of 'expanded'
            sx={expanded ? { textAlign: 'left', minHeight: '80px' } : { textAlign: 'left', height: '80px', overflowY: 'clip' }}>
            {
              (!description || description === '') // if content is missing
                ? <i>Content is not available</i>  // render this
                : <div className='description-text'>
                  {description}
                </div>// else this
            }
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" sx={{color: "#54c4c9"}}
                onClick={() => setExpanded(!expanded)}>
          {
            expanded ? 'Show Less' : 'Expand'
          }
        </Button>
        <Button size="small" sx={{color: "#54c4c9"}}
                onClick={() => handleMoreLikeThis(doc.id)}>
          {
            'More Like This'
          }
        </Button>
      </CardActions>
    </Card>
  );
}

export default SearchResult;