import React, { useState } from 'react';
import { Button, Card, IconButton, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { buttonTheme } from './FilterPane';
import { source_list } from './Dashboard';
import { fileTypeList } from './FileTypeCheckBoxesGroup';


const NEWSLETTER_API = window.location.origin + '/api/v1/newsletter/add';

function getSourcesSelected(sourceValues) {
    // if all are selected
    if (Object.values(sourceValues).every((item) => item) || Object.values(sourceValues).every((item) => !item)) {
        return "ALL";
    }
    else {
        // get the selected sources from checkbox
        let sourcesSelected = Object.entries(sourceValues).filter((item) => item[1]);
        sourcesSelected = sourcesSelected.map(([k, v]) => k);
        // get the full names to display
        let sourcesSelectedFullNames = source_list.filter((item) => sourcesSelected.includes(item.name));
        sourcesSelectedFullNames = sourcesSelectedFullNames.map((item) => item.fullName);
        return sourcesSelectedFullNames.join(", ");
    }
}

function getFileTypesSelected(fileTypeValues) {
    // if all are selected
    if (Object.values(fileTypeValues).every((item) => item) || Object.values(fileTypeValues).every((item) => !item)) {
        return "ALL";
    }
    else {
        // get the selected types from checkbox
        let typesSelected = Object.entries(fileTypeValues).filter((item) => item[1]);
        typesSelected = typesSelected.map(([k, v]) => k);
        // get the labels to display
        let typesSelectedLabels = fileTypeList.filter((item) => typesSelected.includes(item.name));
        typesSelectedLabels = typesSelectedLabels.map((item) => item.label);
        return typesSelectedLabels.join(", ");
    }
}

export default function Subscribe({ searchTerm, sourceValues, fileTypeValues, subscriptionWarningNeeded, newsletterQuery, handleSubscribeClose }) {

    const [subscriptionMessage, setSubscriptionMessage] = useState(null);
    const [successfulSubscription, setSuccessfulSubscription] = useState(false); // used for determining the text color for the message
    const displayText_SourcesSelected = getSourcesSelected(sourceValues);
    const displayText_TypesSelected = getFileTypesSelected(fileTypeValues); 
    //user email
    let user_email = null;

    const [newsletterFrequency, setNewsletterFrequency] = useState('weekly');

    const handleNewsletterFrequencyChange = (event) => {
        setNewsletterFrequency(event.target.value);
    };

    function handleClose() {
        handleSubscribeClose();
        setSubscriptionMessage(null); // reset the message after the window closes
    }

    const handleSubmit = async event => {
        let data = {
            "username": "",
            "email": "",
            "query": newsletterQuery.slice(1), // "?" part of the query is not needed by the newsletter API, but "q=" is
            "frequency": newsletterFrequency
        }

        let res = await fetch(NEWSLETTER_API, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        });
        let resJson = await res.json();

        // Handle response
        if (res.status === 201) {
            setSuccessfulSubscription(true);
            setSubscriptionMessage("You have successfully subscribed to this topic!");
            setTimeout(handleClose, 2000); // automatically close the window after 2 secs
        }
        else if (res.status === 406) {
            setSuccessfulSubscription(false);
            setSubscriptionMessage("You have already subscribed to this topic! Please change the search term or the sources selected.");
        }
        else if (res.status === 400){
            setSuccessfulSubscription(false);
            setSubscriptionMessage('Please check the subscription values provided.');
        }
        else {
            setSuccessfulSubscription(false);
            setSubscriptionMessage('Something went wrong!');
        }
    };

    return (
        <Card sx={{ padding: '0 5% 10% 5%' }}>
            <div style={{width: "fit-content"}}>
                <Typography variant='h4' sx={{marginTop: "5%", color: "#133369", fontWeight: "bold"}}>Subscribe to this topic</Typography>
                <IconButton onClick={handleClose} sx={{ right: '4%', top: '0', position: 'fixed' }}>
                    <CloseIcon />
                </IconButton>
                <div style={{ textAlign: 'left' }}>
                    <div style={{ marginTop: '5%' }}>
                        <Typography><b><u>Search Term:</u></b> {searchTerm.length > 0 ? searchTerm : "\"\""}
                        </Typography>
                    </div>
                    <div style={subscriptionWarningNeeded ? {marginTop: '1%'} : {display: 'none'}}>
                        <Typography sx={{color: "mediumvioletred"}}>
                            { "Your search term lead to 0 results. " +
                                "Please check your spelling or consider using fuzzy search" +
                                " as the newsletter service will not apply it by itself."}
                        </Typography>
                    </div>
                    <div style={{ marginTop: '5%' }}>
                        <Typography><b><u>Selected Sources:</u></b> {displayText_SourcesSelected}</Typography>
                    </div>
                    <div style={{ marginTop: '5%' }}>
                        <Typography><b><u>Selected File Types:</u></b> {displayText_TypesSelected}</Typography>
                    </div>
                    <div style={{ marginTop: '5%' }}>
                        <Typography>I would like to receive newsletter emails</Typography>
                        <FormControl size='small'>
                            <Select
                                labelId="frequency"
                                id="frequency-select"
                                value={newsletterFrequency}
                                onChange={handleNewsletterFrequencyChange}
                            >
                                <MenuItem value={'daily'}>daily</MenuItem>
                                <MenuItem value={'weekly'}>weekly (every Tuesday)</MenuItem>
                                <MenuItem value={'monthly'}>monthly (1st day of each month)</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={subscriptionMessage ? { marginTop: '7%' } : {display: 'none'}}>
                        <Typography sx={successfulSubscription ? {color: "#133369"} : {color: "mediumvioletred"}}>
                            {subscriptionMessage}
                        </Typography>
                    </div>
                    <div style={{ marginTop: '7%' }}>
                        <ThemeProvider theme={buttonTheme}>
                            <Button type='submit' name="subscriptionSubmit" variant="contained"
                                    onClick={handleSubmit} endIcon={<BookmarkAddIcon />}>
                                Subscribe
                            </Button>
                            <Button name="subscriptionSubmit" variant="contained" sx={{marginLeft: "5%"}}
                                    onClick={handleClose} endIcon={<CloseIcon />}>
                                Cancel
                            </Button>
                        </ThemeProvider>

                    </div>
                </div>
            </div>
        </Card>

    );
}