import React from 'react';
import Checkbox from '@mui/material/Checkbox';


export default function DaiCheckBox(label) {
    // Custom Check Box Design for PIA
    return (
        <Checkbox
          {...label}          
          sx={{
            color: "#133369",
            '&.Mui-checked': {
                color: "#133369",
            },
            '&:hover': {
              color: "#54c4c9",
              },
          }}
        />
      );
}