import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DaiCheckBox from './DaiCheckBox';
import { FormGroup } from '@mui/material';
import { source_list } from './Dashboard';


export default function SourcesCheckBoxesGroup({states, handleChange}) {
    return (
    <FormControl sx={{'textAlign': 'left', 'paddingRight': '1.8rem'}}>
        <FormLabel id="sources-check-box-group-label" sx={{color: '#133369', '&.Mui-focused': {color: "#133369"}}}>Sources</FormLabel>
        <FormGroup>
            <FormControlLabel checked={states.all} name='all'
                control={<DaiCheckBox />} 
                label="All" onChange={e => handleChange(e)}/>

            {source_list.map((source) => (
            <FormControlLabel checked={states[source.name]} name={source.name}
                control={<DaiCheckBox />}
                label={source.fullName} 
                onChange={e => handleChange(e)} />
            ))}


        </FormGroup>

    </FormControl>
    );
}