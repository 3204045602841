import React, { useEffect, useState, useTransition } from 'react';
import { Card, CardActionArea, CardActions, CardContent, Container, Grid, Link, Typography } from '@mui/material';
import { API_URL } from './App';
import dayjs from 'dayjs';

const getNumDocs = async (host) => {
    const q = "?q=*:*";
    const fq = "&fq=host:" + host;
    const response = await fetch(`${API_URL}${q}${fq}&rows=0`);
    const data = await response.json();
    return (data.response.numFound);
}

const getNumDocsLastWeek = async (host) => {
    const q = "?q=*:*";
    const fq = "&fq=host:" + host + "&fq=date:[NOW-7DAY TO *]";
    const response = await fetch(`${API_URL}${q}${fq}&rows=0`);
    const data = await response.json();
    return (data.response.numFound);
}

const getLastCrawled = async (host) => {
    const q = "?q=*:*";
    const fq = "&fq=host:" + host;
    const sort = "&sort=date desc"
    const response = await fetch(`${API_URL}${q}${fq}${sort}&rows=1`);
    const data = await response.json();
    if (data.response.docs.length === 0)
        return "Never"
    return (dayjs(data.response.docs[0].date[0]).format('DD.MM.YYYY'));
}

// used in SourcesCheckBoxesGroup.js and SearchResult.js
export const source_list = [{
    name: "resmi",
    url: "www.resmigazete.gov.tr",
    fullName: "Resmi Gazete",
    logo: "resmigzt_logo.png",
}, {
    name: "ticaret",
    url: "ticaret.gov.tr",
    fullName: "Ticaret Bakanlığı",
    logo: "ticaretbkn_logo.png",
}, {
    name: "sanayi",
    url: "www.sanayi.gov.tr",
    fullName: "Sanayi Bakanlığı",
    logo: "sanayibkn_logo.jpeg",
}, {
    name: "yatirim",
    url: "www.yatirimadestek.gov.tr",
    fullName: "yatirimadestek.gov.tr",
    logo: "yatirimadestek_logo.jpeg",
}, {
    name: "itkib",
    url: "www.itkib.org.tr",
    fullName: "İTKIB",
    logo: "itkib_logo.png",
}, {
    name: "tubitak",
    url: "tubitak.gov.tr",
    fullName: "TÜBİTAK",
    logo: "tubitak_logo.jpg",
}, {
    name: "csb",
    url: "www.csb.gov.tr",
    fullName: "T.C. Çevre, Şehircilik ve İklim Değişikliği Bakanlığı",
    logo: "csb_logo.png",
}, {
    name: "csgb",
    url: "www.csgb.gov.tr",
    fullName: "T.C. Çalışma ve Sosyal Güvenlik Bakanlığı",
    logo: "csgb_logo.png",
}, {
    name: "mevzuat",
    url: "www.mevzuat.gov.tr",
    fullName: "Mevzuat Bilgi Sistemi",
    logo: "mevzuat_logo.jpg",
}, {
    name: "eutaxtation",
    url: "taxation-customs.ec.europa.eu",
    fullName: "Taxation and Customs Union",
    logo: "europeancommission_logo.png",
}, {
    name: "efrag",
    url: "www.efrag.org",
    fullName: "EFRAG",
    logo: "efrag_logo.jpg",
}, {
    name: "eurlex",
    url: "eur-lex.europa.eu",
    fullName: "EUR-Lex",
    logo: "eurlex_logo.png",
}, {
    name: "eucommission",
    url: "commission.europa.eu",
    fullName: "European Commission",
    logo: "europeancommission_logo.png",
}];

export default function Dashboard({ loadDashboard }) {

    useEffect(() => {
        if (loadDashboard) {
            source_list.map((source) => {
                let promise = getNumDocs(source.url);
                promise.then((value) => source.numDocs = value.toLocaleString("tr"));

                promise = getNumDocsLastWeek(source.url);
                promise.then((value) => source.numDocsLastWeek = value.toLocaleString("tr"));

                promise = getLastCrawled(source.url);
                promise.then((value) => source.lastCrawled = value);

            });
        }

    }, []);
    return (
        <Container>
            <Grid container spacing={2}>
                {source_list.map((source) => (
                    <Grid item xs={6} md={4}>
                        <Card sx={{height: '100%'}}>
                            <CardActionArea href={window.location.origin + `/?q=&fq=host:${source.url}&page=1`} rel="noopener noreferrer">
                                <CardContent>
                                    <div className='source-image'>
                                        <img src={source.logo} alt='Source Logo'></img>
                                    </div>
                                    <div className='source-fullName'>
                                        <Typography variant='body1'>
                                            <b>{source.fullName}</b>
                                        </Typography>
                                    </div>
                                    <div className='source-stats'>
                                        <Typography variant='caption' sx={{color: '#133369'}}>
                                            <b>Number of Documents Indexed: </b> {source.numDocs}
                                        </Typography>
                                        <Typography variant='caption' sx={{color: '#133369'}}>
                                            <b>Number of Documents Released Past Week: </b> {source.numDocsLastWeek}
                                        </Typography>
                                        <Typography variant='caption' sx={{color: '#133369'}}>
                                            <b>Last Crawled on: </b> {source.lastCrawled}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                </Grid>
                ))}
            </Grid>
        </Container>
    );
}
