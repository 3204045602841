import React, { useEffect, useState } from 'react';
import './App.css';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography
} from '@mui/material';
import {Container} from "@mui/system";
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TuneIcon from "@mui/icons-material/Tune";
import Switch from "@mui/joy/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Login from "./Login";
import SearchToolTip from "./SearchToolTip";

const BASE_API_URL = window.location.origin + '/api/v1/';
export default function Subscriptions ({ loadSubscriptions }) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [numTotalSubscriptions, setNumTotalSubscriptions] = useState(-1);
    const [showSubscribePopup, setShowSubscribePopup] = useState(false);


    const getUserSubscriptions = async () => {
        let response = await fetch(`${BASE_API_URL}newsletter/show_subscriptions`);
        let data = await response.json();

        // Update the value of subscription
        if (!response.ok){
            setSubscriptions([]);
            setNumTotalSubscriptions(0);
            if (data.detail !== null) {
                setErrorMessage(data.detail.toString() + " Please reload the page or contact the Admin.");
            } else if (data.statusText !== null) {
                setErrorMessage(data.statusText.toString() + " Please reload the page or contact the Admin.");
            } else {
                setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
            }
        } else {
            console.log(data);
            setSubscriptions(data);
            console.log(subscriptions);
            setNumTotalSubscriptions(data.size);
            return data;
        }
    }

    const toggleActivation = async (hash) => {
        let response = await fetch(`${BASE_API_URL}newsletter/toggle_subscription?subscription_hash=${hash}`);
        let data = await response.json();

        // Update the value of subscription
        if (!response.ok){
            if (data.detail !== null) {
                setErrorMessage(data.detail.toString());
            } else if (data.statusText !== null) {
                setErrorMessage(data.statusText.toString());
            } else {
                setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
            }
        } else {
            console.log("toggled activation");
            await getUserSubscriptions();
        }
    }

    const changeFrequency = async (hash, newFrequency) => {
        let response = await fetch(`${BASE_API_URL}newsletter/change_frequency?subscription_hash=${hash}&frequency=${newFrequency}`);
        let data = await response.json();

        console.log(data);
        // Update the value of subscription
        if (!response.ok){
            if (data.detail !== null) {
                setErrorMessage(data.detail.toString());
            } else if (data.statusText !== null) {
                setErrorMessage(data.statusText.toString());
            } else {
                setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
            }
        } else {
            console.log("frequency changed");
            await getUserSubscriptions();
        }

    }

    const unsubscribe = async (hash) => {
        let response = await fetch(`${BASE_API_URL}newsletter/unsubscribe?subscription_hash=${hash}`);
        // Update the value of subscription

        if (!response.ok){
            let data = await response.json();
            if (data.detail !== null) {
                setErrorMessage(data.detail.toString());
            } else if (data.statusText !== null) {
                setErrorMessage(data.statusText.toString());
            } else {
                setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
            }
        } else {
            console.log("unsubscribed");
            setSubscriptions(subscriptions.filter(s => s.subscription_hash !== hash));
            setNumTotalSubscriptions(numTotalSubscriptions - 1);
        }
    }


    useEffect(() => {
        if (loadSubscriptions) {  //  && token !== null
            getUserSubscriptions();
            // promise.then(value));
            // promise.then((value) => setSubscriptions(value));
            setNumTotalSubscriptions(subscriptions.length);
            console.log("number of subs: " + numTotalSubscriptions);
        }

    }, [loadSubscriptions]);

    useEffect(() => {
        console.log("other useeffect: " + subscriptions + " size: " + subscriptions.length);
        // setSubscriptions(subscriptions);
        setNumTotalSubscriptions(subscriptions.length);
        console.log("after other useeffect: " + subscriptions.toString() + " size: " + subscriptions.length);
    }, [subscriptions])

    const handleNewsletterFrequencyChange = (event) => {
        console.log(event.target.value);
    };

    function getSearchTerm(content) {
        const endIndex = content.includes("&sort") ? content.indexOf("&sort") : content.length;
        return content.substr(0,  endIndex);
    }

    return (
      <div className="Subscriptions" style={numTotalSubscriptions >= 0 ? {'display': 'block'} : {'display': 'none'}}>
          <Container>
              <Grid container spacing={2}>
                  {
                      numTotalSubscriptions > 0
                        ? subscriptions.map((subscription) => (  // if there are more than 0 results
                          <Grid item xs={12} md={6}>
                              <Card sx={{minWidth: 275}}>
                                  <CardContent>
                                    <div className="subscription-main">
                                        <div className="subscription-info">
                                            <div className='subscription-searchTerm'>
                                                <Typography><b>Search Term:</b> {getSearchTerm(subscription.query.content[0])}</Typography>
                                            </div>
                                            <div className='subscription-frequency'>
                                                <Typography><b>Newsletter Frequency: </b><br></br>
                                                    {/* TODO Enable when EDIT is available */}
                                                    <FormControl size='small'>
                                                        <Select
                                                            labelId="frequency"
                                                            id="frequency-select"
                                                            value={subscription.frequency}
                                                            // value={subscription.frequency.charAt(0).toUpperCase() + subscription.frequency.slice(1)}
                                                            onChange={(e) => changeFrequency(subscription.subscription_hash, e.target.value)}
                                                        >
                                                            <MenuItem value={'daily'}>daily</MenuItem>
                                                            <MenuItem value={'weekly'}>weekly (every Tuesday)</MenuItem>
                                                            <MenuItem value={'monthly'}>monthly (1st day of each month)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Typography>
                                            </div>
                                            <div className='subscription-sources'>
                                                <Typography variant='caption'
                                                            sx={{textAlign: 'left'}}>
                                                    <b>Sources: </b> {subscription.query.host != null && subscription.query.host.length !== 0 ? subscription.query.host.join(', ') : "All"}
                                                </Typography>
                                            </div>
                                            <div className='subscription-fileTypes'>
                                                <Typography variant='caption'
                                                            sx={{textAlign: 'left'}}>
                                                    <b>File Types: </b> {subscription.query.type != null && subscription.query.type.length !== 0 ? subscription.query.type.join(', ') : "All"}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className='subscription-actions'>
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <Switch size="lg"
                                                                variant="solid"
                                                                checked={subscription.activated.toLowerCase() === "true"}
                                                                onClick={() => toggleActivation(subscription.subscription_hash)} />
                                                    }/>
                                            </div>
                                            <div >
                                                <Button size="large"
                                                    startIcon={<DeleteForeverIcon />}
                                                    sx={{padding: "0x 4px 0px 4px", color: '#133369', '&:hover': {backgroundColor: '#fff', color: 'red' }}}
                                                    onClick={() => unsubscribe(subscription.subscription_hash)}>
                                                        Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                  </CardContent>
                              </Card>
                          </Grid>
                        ))
                        : (  // else
                          <Grid item xs={12} md={12}>
                              <h2>{errorMessage !== "" ? errorMessage : "No Subscriptions Found"}</h2>
                          </Grid>
                        )
                  }
              </Grid>
          </Container>
      </div>

    );
}
