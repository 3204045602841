import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DaiRadio from './DaiRadio';


export default function SortByRadioButtonsGroup({value, handleClick}) {
    return (
    <FormControl sx={{'textAlign': 'left', 'paddingRight': '1.8rem'}}>
        <FormLabel id="sortby-radio-buttons-group-label" sx={{color: '#133369', '&.Mui-focused': {color: "#133369"}}}>Sort By</FormLabel>
        <RadioGroup
        defaultValue="relevance"
        value={value}
        aria-labelledby="sortby-radio-buttons-group-label"
        name="radio-buttons-sortby"
        >
            <FormControlLabel value="relevance" control={<DaiRadio />} label="Relevance" onClick={e => handleClick(e)}/>
            <FormControlLabel value="date" control={<DaiRadio />} label="Creation Date" onClick={e => handleClick(e)}/>
            <FormControlLabel value="lastModified" control={<DaiRadio />} label="Last Modified Date" onClick={e => handleClick(e)}/>
            {/* <FormControlLabel value="end_date" control={<DaiRadio />} label="Due Date" onClick={e => handleClick(e)}/> */}
        </RadioGroup>

    </FormControl>
    );
}