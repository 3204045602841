import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
const pages = ['PIA'];
const settings = ['Profile', 'Account', 'Dashboard'];

function ResponsiveAppBar({ isAuth, setIsAuth }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {

  }

  const handleLogout = () => {
    setIsAuth(false);
    localStorage.removeItem("username");

    if (document.cookie.indexOf('auth_token') > -1) {
      // delete cookie
      document.cookie = "auth_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=" + window.location.hostname;
      window.location.reload();
    }
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {/* Large screen view */}
          <Box sx={{ flexGrow:1, flexBasis:0, justifyContent:'left', display: { xs: 'none', md: 'flex' }}}>
            {/* {pages.map((page) => ( */}
              <Button
                // key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', fontSize: '2rem', margin: '0', textTransform: 'none' }}
              >
                {/* {page} */}
                <a href={window.location.origin + `/?q=&page=1`}><img src="kordsa_logo.png" alt="kordsa logo"/></a>
              </Button>
            {/* ))} */}
          </Box>

          <Box sx={{ flexGrow: 1, flexBasis:0, justifyContent:'right', alignItems:'center', display: { xs: 'none', md: 'flex' }}}>
            <Button onClick={handleLogin}
              sx={isAuth ? {display: 'none'} : { color: 'black', fontWeight: 'bold', fontSize: '1rem' }}>
              Login
            </Button>
            <Typography sx={!isAuth ? {display: 'none'} : {fontSize: '1rem', color: 'black'}}>
              Logged in as: <b>{localStorage.getItem("username")}</b>
            </Typography>
            <Button onClick={handleLogout}
              sx={!isAuth ? {display: 'none'} : { color: 'black', fontWeight: 'bold', fontSize: '1rem' }}>
              Logout
            </Button>
          </Box>

          {/* Small screen view */}
          <Box sx={{ flexGrow: 1, justifyContent:'left', display: { xs: 'flex', md: 'none' }}}>
            {/* {pages.map((page) => ( */}
              <Button
                // key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', fontSize: '2rem', margin: '0', textTransform: 'none' }}
              >
                {/* {page} */}
                <a href={window.location.origin + `/?q=&page=1`}><img src="kordsa_logo.png" alt="kordsa logo"/></a>
              </Button>
            {/* ))} */}
          </Box>

          <Box sx={{ flexGrow: 0, justifyContent:'right', display: { xs: 'flex', md: 'none' } }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt="" src="account_circle.png" /> */}
                <AccountCircle sx={{fontSize: 'xxx-large'}}></AccountCircle>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              <MenuItem>
                  <Typography sx={isAuth ? {display: 'none'} : { color: 'black'}}
                  textAlign="center" onClick={handleLogin}>
                    Login
                  </Typography>
              </MenuItem>

              <MenuItem>
                  <Typography sx={!isAuth ? {display: 'none'} : { color: 'black'}}
                  textAlign="center" onClick={handleLogout}>
                    Logout <b>{localStorage.getItem("username")}</b>
                  </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
