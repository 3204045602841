import React, { useEffect, useState, useTransition } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import './App.css';
import { Container } from '@mui/system';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Grid, CircularProgress, Typography, Tooltip } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar';
import SearchResult from './SearchResult';
import { useSearchParams } from 'react-router-dom';
import FilterPane from './FilterPane';
import Dashboard from './Dashboard';
import Subscribe from './Subscribe';
import Subscriptions from "./Subscriptions";
import Login from './Login';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import SearchToolTip from './SearchToolTip';
dayjs.locale('de') // use loaded locale globally

export const API_URL = window.location.origin + "/api/v1/core-kordsa"; //dynamic API address for the Solr server
// export const API_URL = "http://10.0.4.232/solr/core-kordsa/select";
const rows = 10  // number of search results to be dipslayed

//list of sources to append to the query
const resmi = 'host:www.resmigazete.gov.tr';
const ticaret = 'host:ticaret.gov.tr';
const sanayi = 'host:www.sanayi.gov.tr';
const yatirim = 'host:www.yatirimadestek.gov.tr';
const itkib = 'host:www.itkib.org.tr';
const tubitak = 'host:tubitak.gov.tr';
const csb = 'host:www.csb.gov.tr';
const csgb = 'host:www.csgb.gov.tr';
const mevzuat = 'host:www.mevzuat.gov.tr';
const eutaxation = 'host:taxation-customs.ec.europa.eu';
const efrag = 'host:www.efrag.org';
const eurlex = 'host:eur-lex.europa.eu';
const eucommission = 'host:commission.europa.eu';

//list of file types
const html = 'type:html'
const pdf = 'type:pdf'
const docx = 'type:msword type:*document'
const xslx = 'type:*sheet'
const pptx = 'type:*presentation'
// Not so relevant file types
// const xml = 'type:xml'
// const rtf = 'type:rtf'

// A variable to store the query for newsletter subscription. Has to be defined outside of App() function
let newsletterQuery = null;

function formatURLSearchParams(params) {
  // modify URL search params
  const currentURL = new URL(window.location.origin);
  params.forEach((param) => currentURL.searchParams.append(Object.keys(param)[0], Object.values(param)[0]));
  return currentURL.search;
};

function checkSource(fq) {
  // Return the source value of a given query
  let source_list = {
    all: false,
    resmi: false,
    ticaret: false,
    sanayi: false,
    yatirim: false,
    itkib: false,
    tubitak: false,
    csb: false,
    csgb: false,
    mevzuat: false,
    eutaxation: false,
    efrag: false,
    eurlex: false,
    eucommission: false,
  };
  // check if any of the fq parameters include a source
  if (fq.some((item) => item.includes("host:"))) {
    if (fq.some((item) => item.includes(resmi))) {
      source_list.resmi = true;
    }
    if (fq.some((item) => item.includes(ticaret))) {
      source_list.ticaret = true;
    }
    if (fq.some((item) => item.includes(sanayi))) {
      source_list.sanayi = true;
    }
    if (fq.some((item) => item.includes(yatirim))) {
      source_list.yatirim = true;
    }
    if (fq.some((item) => item.includes(itkib))) {
      source_list.itkib = true;
    }
    if (fq.some((item) => item.includes(tubitak))) {
      source_list.tubitak = true;
    }
    if (fq.some((item) => item.includes(csb))) {
      source_list.csb = true;
    }
    if (fq.some((item) => item.includes(csgb))) {
      source_list.csgb = true;
    }
    if (fq.some((item) => item.includes(mevzuat))) {
      source_list.mevzuat = true;
    }
    if (fq.some((item) => item.includes(eutaxation))) {
      source_list.eutaxation = true;
    }
    if (fq.some((item) => item.includes(efrag))) {
      source_list.eufrag = true;
    }
    if (fq.some((item) => item.includes(eurlex))) {
      source_list.eurlex = true;
    }
    if (fq.some((item) => item.includes(eucommission))) {
      source_list.eucommission = true;
    }
  }
  else {
    source_list = {
      all: true,
      resmi: true,
      ticaret: true,
      sanayi: true,
      yatirim: true,
      itkib: true,
      tubitak: true,
      csb: true,
      csgb: true,
      mevzuat: true,
      eutaxation: true,
      efrag: true,
      eurlex: true,
      eucommission: true
    };
  }
  return source_list;
};

function checkType(fq) {
  // Return the source value of a given query
  let type_list = {
    all: false,
    html: false,
    pdf: false,
    docx: false,
    xslx: false,
    pptx: false,
  };
  // check if any of the fq parameters include a source
  if (fq.some((item) => item.includes("type:"))) {
    if (fq.some((item) => item.includes(html))) {
      type_list.html = true;
    }
    if (fq.some((item) => item.includes(pdf))) {
      type_list.pdf = true;
    }
    if (fq.some((item) => item.includes(docx))) {
      type_list.docx = true;
    }
    if (fq.some((item) => item.includes(xslx))) {
      type_list.xslx = true;
    }
    if (fq.some((item) => item.includes(pptx))) {
      type_list.pptx = true;
    }
  }
  else {
    type_list = {
      all: true,
      html: true,
      pdf: true,
      docx: true,
      xslx: true,
      pptx: true,
    };
  }
  return type_list;
};

function checkSort(params) {
  // Check if search params include sort option
  if (params.get('sort')) {
    if (params.get('sort').includes('date')) {
      return 'date';
    }
    if (params.get('sort').includes('lastModified')) {
      return 'lastModified';
    }
    // else if (params.get('sort').includes('end_date')) {
    //   return 'end_date';
    // }
    else {
      return 'relevance';
    }
  }
  // if not sort by relevance
  else {
    return 'relevance';
  }
};

function checkMLT(params) {
  if (params.get('mlt') == "true") {
    return true;
  }
  else {
    return false;
  }
}

function convertToFuzzyQuery(query) {
  // Remove all single and double quotes from the query string
  const cleanedQuery = query.replace(/['"]/g, '');

  // Split the query into words, append '~' for fuzzy search, and join back
  const fuzzyQuery = cleanedQuery
      .split(' ')
      .map(word => word + '~')
      .join(' ');

  return fuzzyQuery;
}

function containsBooleanOperators(query) {
  // Regular expression to detect Boolean operators
  // It looks for 'AND', 'OR', 'NOT', '&&', '||', and '!'
  // The \b denotes a word boundary, ensuring we don't match words that contain these as substrings
  const booleanOperatorsRegex = /\b(AND|OR|NOT|\&\&|\|\||\!)\b/;

  return booleanOperatorsRegex.test(query);
}

function checkAuthCookie() {
  return document.cookie.indexOf('auth_token') > -1;
}

function getAuthCookie() {
  if (checkAuthCookie() === true){
    console.log(document.cookie);
    return document.cookie.split("; ")
      .find((row) => row.startsWith("auth_token="))
      ?.split("=")[1];
  }
}

const App = () => {
  // search params from the url
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  let q = urlSearchParams.get('q') === 'null' || !urlSearchParams.get('q') ? '' : urlSearchParams.get('q');
  let fq = urlSearchParams.getAll('fq') ? urlSearchParams.getAll('fq') : [];
  let source = checkSource(fq);
  let fileType = checkType(fq);
  let sort = checkSort(urlSearchParams);

  const [isAuth, setIsAuth] = useState(checkAuthCookie());

  // show dashboard if q and fq is empty
  const [showDashboard, setShowDashboard] = useState((!q && fq.length === 0));

  const [showSubscriptions, setShowSubscriptions] = useState(true);

  // react state for displaying the subscription dialogue
  const [showSubscribe, setShowSubscribe] = useState(false);

  const [searchBarInput, setSearchBarInput] = useState(q);
  // the query input of the searchDocs
  const [searchTerm, setSearchTerm] = useState(searchBarInput);

  // react state for the filter queries which is an array
  const [filterQueries, setFilterQueries] = useState(fq);

  // react state for the sources filter
  const [sourceValues, setSourceValues] = useState(source);

  // react state for the file type filter
  const [fileTypeValues, setFileTypeValues] = useState(fileType);

  // react state for the sort by , used in searchDocs as well
  const [sortByValue, setSortByValue] = useState(sort);

  // Default array of docs is an empty array
  const [docs, setDocs] = useState([]);
  const [highlights, setHighlights] = useState({});

  const [isPending, startTransition] = useTransition();

  // Pagination control
  const page_id = urlSearchParams.get('page') === null ? 1 : parseInt(urlSearchParams.get('page'));
  const [page, setPage] = useState(page_id);
  const [numTotalDocs, setNumTotalDocs] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  // Fuzzy search control
  const [isFuzzy, setIsFuzzy] = useState(false);

  // MLT control
  const [isMLT, setIsMLT] = useState(checkMLT(urlSearchParams));
  let MLTDocID = '';
  if (isMLT) {
    // strip the document ID from the q= parameter
    MLTDocID = q.substring(q.indexOf('id:"')+4, q.lastIndexOf('"'));
  }
  const [MLTDocTitle, setMLTDocTitle] = useState("Document");

  const searchDocs = async (query, filterQueries, sortBy, start) => {

    // extra manuel control for MLT, needed for refreshing the page
    if (isMLT) {
      setSearchBarInput("");
      searchMLT(MLTDocID);
      return;
    }
    setIsMLT(false);
    // reset the num Docs to -1 to indicate that the documents are still loading.
    // If numTotalDocs >= 0, then the update is done
    setNumTotalDocs(-1);

    let params = [];
    params.push({q: query});

    if (sortBy === 'date') {
      // sort descending by start_date
      params.push({sort: 'date desc'});
    } else if (sortBy === 'lastModified'){
      // sort descending by last_modified date
      params.push({sort: 'lastModified desc'});
    }
    // else if (sortBy === 'end_date') {
    //   // sort descending by end_date
    //   params.push({sort: 'end_date desc'});
    // }

    if (filterQueries.length > 0){
      // params.fq = filterQueries[1];
      filterQueries.forEach((filterQuery) => params.push({fq: filterQuery}));
      // filterQueries.forEach((fq) => fq_params += `&fq=${fq}`);
    }

    // urlParams: Search params displayed in the address bar
    // apiParams: Search params sent to the API
    let urlParams = params;
    urlParams.push({page: page});
    urlParams = formatURLSearchParams(urlParams);

    if (window.location.search.replaceAll("%3A", ":") !== urlParams.replaceAll("%3A", ":")) {
      // update the URLSearchParams if they are changed
      setUrlSearchParams(urlParams);
    }

    // // Check if a special CC search term is given
    // if (query != ccToQuery(query)){
    //   params.find(param => param.q).q = ccToQuery(query);
    // }

    // Check if an empty query or all documents string is given, if true, then show all results sorted by due date
    const isEmptyQuery = params.find(param => (param.q === ''));
    const isAllDocsString = params.find(param => (param.q === '*:*'));
    if (isEmptyQuery || isAllDocsString) {
      if (isEmptyQuery) {
        params.find(param => param.q === '').q = "*:*";
      }
      // if sort is not provided (i.e. sort=relevance), then sort all the results by due dates
      !params.find(param => param.sort) && params.push({sort: 'date desc'});
    }

    let apiParams = formatURLSearchParams(params);
    // Save apiParams as the query term for newsletter subscription
    newsletterQuery = apiParams;

    let response = await fetch(`${API_URL}${apiParams}&hl=true&hl.method=unified&hl.fl=title\%2C\%20content\%2C\%20file_name&rows=${rows}&start=${start}`);
    let data = await response.json();

    // Update the value of docs
    if (!response.ok){
      setDocs([]);
      setNumTotalDocs(0);
      if (data.detail !== null) {
        setErrorMessage(data.detail.toString() + " Please reload the page or contact the Admin.");
      } else if (data.statusText !== null) {
        setErrorMessage(data.statusText.toString() + " Please reload the page or contact the Admin.");
      } else {
        setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
      }
    }
    // if the result is ok but there are 0 results,
    // enhance the results with fuzzy search, but skip fuzzy search for empty requests
    else if (data.response.numFound === 0 && !containsBooleanOperators(query) && params.find(param => param.q).q !== "") {
      params.find(param => param.q).q = convertToFuzzyQuery(query);
      let apiParams = formatURLSearchParams(params);
      let response = await fetch(`${API_URL}${apiParams}&hl=true&hl.method=unified&hl.fl=title%2C%20content%2C%20file_name&rows=${rows}&start=${start}`);
      let data = await response.json();
      setIsFuzzy(true);
      setErrorMessage("");
      setDocs(data.response.docs);
      setNumTotalDocs(data.response.numFound);
      setHighlights(data.highlighting);
    }
    else {
      setIsFuzzy(false);
      setErrorMessage("");
      setDocs(data.response.docs);
      setNumTotalDocs(data.response.numFound);
      setHighlights(data.highlighting);
    }
  }

  const searchMLT = async (docID) => {
    setIsMLT(true);
    // reset the num Docs to -1 to indicate that the documents are still loading.
    // If numTotalDocs >= 0, then the update is done
    setNumTotalDocs(-1);
    let params = [];
    params.push({q: `id:"${docID}"`});
    params.push({mlt: "true"});

    // urlParams: Search params displayed in the address bar
    // apiParams: Search params sent to the API
    params = formatURLSearchParams(params);;

    if (window.location.search.toString() !== params.toString()) {
      // update the URLSearchParams if they are changed
      setUrlSearchParams(params);
    }

    let response = await fetch(`${API_URL}${params}&mlt.fl=content&mlt.count=10`);
    let data = await response.json();

    if (!response.ok){
      setDocs([]);
      setNumTotalDocs(0);
      if (data.detail !== null) {
        setErrorMessage(data.detail.toString() + " Please reload the page or contact the Admin.");
      } else if (data.statusText !== null) {
        setErrorMessage(data.statusText.toString() + " Please reload the page or contact the Admin.");
      } else {
        setErrorMessage("An error occurred. Please reload the page or contact the Admin.");
      }
    }
    else {
      setIsFuzzy(false);
      setErrorMessage("");
      setDocs(data.moreLikeThis[docID].docs);
      setNumTotalDocs(data.moreLikeThis[docID].docs.length);
      setMLTDocTitle(data.response.docs[0].title ? data.response.docs[0].title[0] : data.response.docs[0].file_name[0]);
      setHighlights({});
      // reset filters
      setSortByValue("relevance");
      let newSourceValues = sourceValues;
      Object.keys(newSourceValues).forEach(v => newSourceValues[v] = true);
      setSourceValues(newSourceValues);
      let newFileTypeValues = fileTypeValues;
      Object.keys(newFileTypeValues).forEach(v => newFileTypeValues[v] = true);
      setFileTypeValues(newFileTypeValues);
    }
  }

  useEffect(() => {
    // Set new page value when the page value in URL changes
    if (isMLT) {
      setSearchBarInput('');
      // setSearchTerm('');
    }
    else {
      startTransition(() => {
        // Mark updates as transitions
        searchDocs(searchTerm, filterQueries, sortByValue, 0);
      });
    }
  }, [isMLT]);

  // hook to insert the value of the q= parameter of URL to the search bar
  // when searched directly from the address bar
  useEffect(() => {
    // Set new page value when the page value in URL changes
    setPage(page_id);
    isMLT ? setSearchBarInput("") : setSearchBarInput(searchTerm);
  }, [urlSearchParams]);

  function handleSource(event) {
    let newSourceValues = {...sourceValues};
    if (event.target.name === 'all') {
      // if 'All' option is already selected, deselect everything
      if (sourceValues.all) {
        Object.keys(newSourceValues).forEach(v => newSourceValues[v] = false);
      }
      // if not, then select everything
      else {
        Object.keys(newSourceValues).forEach(v => newSourceValues[v] = true);
      }
    }
    else {
      newSourceValues = {...sourceValues, [event.target.name]: event.target.checked};
      const non_all_keys = Object.keys(newSourceValues).filter((key) => key != "all"); // all sources except 'all'
      // if every source other than all is already selected, check 'all' as well
      non_all_keys.every((key) => newSourceValues[key]) ? newSourceValues.all = true : newSourceValues.all = false;
    }
    setSourceValues(newSourceValues);
  }

  function handleFileType(event) {
    let newFileTypeValues = {...fileTypeValues};
    if (event.target.name === 'all') {
      // if 'All' option is already selected, deselect everything
      if (fileTypeValues.all) {
        Object.keys(newFileTypeValues).forEach(v => newFileTypeValues[v] = false);
      }
      // if not, then select everything
      else {
        Object.keys(newFileTypeValues).forEach(v => newFileTypeValues[v] = true);
      }
    }
    else {
      newFileTypeValues = {...fileTypeValues, [event.target.name]: event.target.checked};
      const non_all_keys = Object.keys(newFileTypeValues).filter((key) => key != "all"); // all types except 'all'
      // if every type other than all is already selected, check 'all' as well
      non_all_keys.every((key) => newFileTypeValues[key]) ? newFileTypeValues.all = true : newFileTypeValues.all = false;
    }
    setFileTypeValues(newFileTypeValues);
  }

  function handleSortBy(event) {
    setSortByValue(event.target.value);
  }

  // hook to modify search term when the source is changed
  useEffect(() => {
    let new_filterQueries = [...filterQueries];

    // index of the fq for source, if it does not exist, push at the end
    const index = new_filterQueries.some((item) => item.includes("host:")) ?
        new_filterQueries.findIndex((item) => item.includes("host:")) : new_filterQueries.length;

    if (sourceValues.all) {
      // remove the fq containing sources
      new_filterQueries.splice(index, 1);
    }
    // at least one source is checked, but all is not checked
    else if (Object.values(sourceValues).some((value) => value)) {
      let source_fq = "";
      if (sourceValues.resmi) {
        source_fq = source_fq + resmi + " ";
      }
      if (sourceValues.ticaret) {
        source_fq = source_fq + ticaret + " ";
      }
      if (sourceValues.sanayi) {
        source_fq = source_fq + sanayi + " ";
      }
      if (sourceValues.yatirim) {
        source_fq = source_fq + yatirim + " ";
      }
      if (sourceValues.itkib) {
        source_fq = source_fq + itkib + " ";
      }
      if (sourceValues.tubitak) {
        source_fq = source_fq + tubitak + " ";
      }
      if (sourceValues.csb) {
        source_fq = source_fq + csb + " ";
      }
      if (sourceValues.csgb) {
        source_fq = source_fq + csgb + " ";
      }
      if (sourceValues.mevzuat) {
        source_fq = source_fq + mevzuat + " ";
      }
      if (sourceValues.eutaxation) {
        source_fq = source_fq + eutaxation + " ";
      }
      if (sourceValues.efrag) {
        source_fq = source_fq + efrag + " ";
      }
      if (sourceValues.eurlex) {
        source_fq = source_fq + eurlex + " ";
      }
      if (sourceValues.eucommission) {
        source_fq = source_fq + eucommission + " ";
      }
      new_filterQueries[index] = source_fq;
    }
    // if no values are selected, do nothing and exit early
    else {
      return;
    }
    setFilterQueries(new_filterQueries);
  }, [sourceValues]);

  // hook to modify search term when the file type is changed
  useEffect(() => {
    let new_filterQueries = [...filterQueries];

    // index of the fq for type, if it does not exist, push at the end
    const index = new_filterQueries.some((item) => item.includes("type:")) ?
        new_filterQueries.findIndex((item) => item.includes("type:")) : new_filterQueries.length;

    if (fileTypeValues.all) {
      // remove the fq containing types
      new_filterQueries.splice(index, 1);
    }
    // at least one type is checked, but all is not checked
    else if (Object.values(fileTypeValues).some((value) => value)) {
      let type_fq = "";
      if (fileTypeValues.html) {
        type_fq = type_fq + html + " ";
      }
      if (fileTypeValues.pdf) {
        type_fq = type_fq + pdf + " ";
      }
      if (fileTypeValues.docx) {
        type_fq = type_fq + docx + " ";
      }
      if (fileTypeValues.xslx) {
        type_fq = type_fq + xslx + " ";
      }
      if (fileTypeValues.pptx) {
        type_fq = type_fq + pptx + " ";
      }
      new_filterQueries[index] = type_fq;
    }
    // if no values are selected, do nothing and exit early
    else {
      return;
    }
    setFilterQueries(new_filterQueries);
  }, [fileTypeValues]);

  // hook to perform search when the search term or filter is modified
  useEffect(() => {
    // setCcFilterValue(checkCC(searchTerm));
    // // after search with search box, reverts the check box group to all selected
    // setSourceValues(checkSource(searchTerm));

    // set showDashboard to false when fq exists
    let fqExists = filterQueries.length > 0 ? true : false;
    if (fqExists) {
      setShowDashboard(false);
      setShowSubscribe(false);
    }

    // if current page is 1, then perform search; else reset page to 1
    if (page === 1) {
      startTransition(() => {
        // Mark updates as transitions
        searchDocs(searchTerm, filterQueries, sortByValue, 0);
      });
    }
    else {
      // When search is performed from address bar
      setPage(1);
    }
  }, [searchTerm, filterQueries, sortByValue]);

  // hook for pagination, perform search when page is changed
  useEffect(() => {
    // set start value
    let start = page * rows - rows; // e.g. if page is set to 3, start is 3*10-10 = 20
    startTransition(() => {
      // Mark updates as transitions
      searchDocs(searchTerm, filterQueries, sortByValue, start);
    });
  }, [page]);

  function searchFromSearchBar(searchInput) {
    // Used when search bar is used for search
    setIsMLT(false);

    // if any other fq than source or type is present, delete from search params
    if(filterQueries.findIndex((item) => (!item.includes("host:") && !item.includes("type:"))) >= 0) {
      let new_filterQueries = [...filterQueries];
      const index = new_filterQueries.findIndex((fq) => (!fq.includes("host:") && !fq.includes("type:")));
      new_filterQueries.splice(index, 1);
      setFilterQueries(new_filterQueries);
    }
    setSearchTerm(searchInput); //searchDocs
    setSortByValue('relevance'); // sort results by relevance
    setShowDashboard(false);
    setShowSubscriptions(false);
  }

  useEffect(() => {
    // The following line is a check for Next.js
    if (typeof window === "undefined") return false;
    // A function to handle the event, where the fix code will be
    const handleBackButton = () => {
      window.history.go(0); // or use history.go() without adding 0
    }
    // Handle back event
    window.addEventListener("popstate", handleBackButton)
    // clearing the event
    return () => {
      window.removeEventListener("popstate", handleBackButton)
    }
  }, [])

  function handleSubscribeShow() {
    setShowSubscribe(true)
  }

  function handleSubscribeClose() {
    setShowSubscribe(false)
  }

  function onPageChange(event, value) {
    setPage(value);
  }

  function handleMoreLikeThis(value) {
    // setSearchBarInput("");

    startTransition(() => {
      // Mark updates as transitions
      searchMLT(value);
    });
  }

  useEffect(() => {
    // Update the document title using the browser API
    const auth = checkAuthCookie();
    console.log('Hook ', auth);
  }, []);

  return (
    <div className="App">
      <div className='AppBar' style={!isAuth ? { opacity: 0.5, pointerEvents: 'none' } : { opacity: 1 }}>
        <ResponsiveAppBar isAuth={isAuth} setIsAuth={setIsAuth} />
      </div>
      <div className='login' style={!isAuth ? { display: 'block' } : { display: 'none' }}>
        <Container align="center">
          <div className='login-box'>
            <Login isAuth={isAuth} setIsAuth={setIsAuth}></Login>
          </div>
        </Container>
      </div>
      <div className='subscribe' style={showSubscribe ? { display: 'block' } : { display: 'none' }}>
          <Container align="center">
            <div className='subscribe-box'>
              <Subscribe searchTerm={searchTerm} sourceValues={sourceValues} fileTypeValues={fileTypeValues}
                         subscriptionWarningNeeded={isFuzzy} newsletterQuery={newsletterQuery}
                         handleSubscribeClose={handleSubscribeClose}></Subscribe>
            </div>
          </Container>
        </div>
      <div className='main' style={showSubscribe || !isAuth ? {opacity: 0.5, pointerEvents: 'none'} : {opacity: 1}}>
        <div className="SearchBar">
          <Container align='center'>
            <div className='SearchHelp'>
              <SearchToolTip></SearchToolTip>
            </div>
            <Paper
              component="form"
              sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '99%'}}
            >
              <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder="Search"
                value={searchBarInput}
                // When a search term is entered to the input field, update the value of it
                onChange={(e) => setSearchBarInput(e.target.value)}
                // Mimic the click action for the ENTER button
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    searchFromSearchBar(searchBarInput);
                  }
                }}
                inputProps={{'aria-label': 'search'}}
              />
              {/* searchdocs */}
              <IconButton type="button" sx={{p: '10px'}} aria-label="search"
                          onClick={() => searchFromSearchBar(searchBarInput)}>
                <SearchIcon/>
              </IconButton>
            </Paper>
          </Container>
        </div>
        {/* <div className='Filters' onChange={e => handleCCChange(e)}> */}
        <div className='Filters'>
          <Container sx={{'paddingBottom': '10px'}}>
            <FilterPane sourceValues={sourceValues} handleSource={handleSource}
                        fileTypeValues={fileTypeValues} handleFileType={handleFileType}
                        sortByValue={sortByValue} handleSortBy={handleSortBy} showDashboard={showDashboard}
                        handleSubscribeShow={handleSubscribeShow}></FilterPane>
          </Container>
        </div>
        <div className="Dashboard" style={showDashboard ? {display: 'block'} : {display: 'none'}}>
          <Dashboard loadDashboard={showDashboard}></Dashboard>
        </div>
        <div className="Subscriptions" style={showSubscriptions ? {display: 'block'} : {display: 'none'}}>
          <Subscriptions loadSubscriptions={showSubscriptions}></Subscriptions>
        </div>
        <div className="Search" style={showDashboard || showSubscriptions ? {display: 'none'} : {display: 'block'}}>
          <div className="Progress"
               style={isPending || numTotalDocs === -1 ? {'display': 'block'} : {'display': 'none'}}>
            <Container>
              <CircularProgress sx={{'color': '#133369'}}/>
            </Container>
          </div>
          <div className="Pagination" style={numTotalDocs > 0 && !isPending ? {
            display: 'block',
            marginBottom: '0.5rem'
          } : {'display': 'none'}}>
            <Container>
              <Stack spacing={2} alignItems={"center"}>
                <Pagination
                  showFirstButton
                  showLastButton
                  page={page}
                  count={Math.ceil(numTotalDocs / 10)}
                  size="small"
                  shape="rounded"
                  sx={{
                    button: {
                      color: "#133369",
                      '&.Mui-selected': {
                        backgroundColor: "#133369",
                        color: "white"
                      },
                    },
                    div: {
                      color: "#133369"
                    }
                  }}
                  onChange={onPageChange}
                />
              </Stack>
              <div className="Count">
                {/* hide this if either fuzzy or MLT search is active */}
                <Typography variant='subtitle2' sx={(isFuzzy || isMLT) && {display: "none"}}>
                  <b>{numTotalDocs.toLocaleString("de")}</b> results found in total
                </Typography>
                {/* when fuzzy search is applied */}
                <Typography variant='subtitle2' sx={!isFuzzy && {display: "none"}}>
                  <b>0</b> results found with the original query. Fuzzy search is applied to enhance results
                  (<b>{numTotalDocs.toLocaleString("de")}</b> results found).
                </Typography>
                {/* when MLT search is applied */}
                <Typography variant='subtitle2' sx={!isMLT && {display: "none"}}>
                  Showing top <b>{numTotalDocs.toLocaleString("de")}</b> most similar documents to <a
                  href={MLTDocID}>{MLTDocTitle}</a>.
                </Typography>
              </div>
            </Container>
          </div>
          {/* If numTotalDocs >= 0, then the update is done */}
          <div className="Results" style={!isPending && numTotalDocs >= 0 ? {'display': 'block'} : {'display': 'none'}}>
            <Container>
              <Grid container spacing={2}>
                {
                  docs?.length > 0
                    ? docs.map((doc) => (  // if there are more than 0 results
                      <Grid item xs={12} md={12} key={doc.id}>
                        <SearchResult doc={doc} highlight={highlights[doc.url]} isMLT={isMLT}
                                      handleMoreLikeThis={handleMoreLikeThis}/>
                      </Grid>
                    ))
                    : (  // else
                      <Grid item xs={12} md={12}>
                        <h2>{errorMessage !== "" ? errorMessage : "No Results Found"}</h2>
                      </Grid>
                    )
                }
              </Grid>
            </Container>
          </div>
          <div className="Pagination" style={numTotalDocs > 0 && !isPending ? {
            display: 'block',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          } : {'display': 'none'}}>
            <Container>
              <Stack spacing={2} alignItems={"center"}>
                <Pagination
                  showFirstButton
                  showLastButton
                  page={page}
                  count={Math.ceil(numTotalDocs / 10)}
                  size="small"
                  shape="rounded"
                  sx={{
                    button: {
                      color: "#133369",
                      '&.Mui-selected': {
                        backgroundColor: "#133369",
                        color: "white"
                      },
                    },
                    div: {
                      color: "#133369"
                    }
                  }}
                  onChange={onPageChange}
                />
              </Stack>
            </Container>
          </div>
        </div>


      </div>

    </div>
  );
}

export default App;
