import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import SortByRadioButtonsGroup from './SortByRadioButtonsGroup';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SourcesCheckBoxesGroup from './SourcesCheckBoxesGroup';
import FileTypeCheckBoxesGroup from './FileTypeCheckBoxesGroup';
import './App.css';

// custom theme for the button style
export const buttonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: "#133369",
                    '&:hover': {
                    backgroundColor: "#54c4c9",
                    color: "#fff",
                    },
                },
            },
        },
    },
});


export default function FilterPane(props) {
    const [collapsed, setCollapsed] = useState(true);

    const handleChange = () => {
        setCollapsed((prev) => !prev);
    };

    return (
        <Box sx={{'display': 'flex', 'flexDirection': 'column', 'alignItems': 'flex-start'}}>
            <div className='filter-pane-buttons'>
                <ThemeProvider theme={buttonTheme}>
                    <Button endIcon={collapsed ? <TuneIcon /> : <CloseIcon />}
                        onClick={() => handleChange()} variant='contained'>
                        {
                            collapsed ? 'Apply Filter' : 'Close'
                        }
                    </Button>
                    <Button endIcon={<BookmarkAddIcon />}
                        sx={props.showDashboard ? {display: 'none'} : {}}
                        onClick={() => props.handleSubscribeShow()} variant='contained'>
                        {
                            'Subscribe to This Topic'
                        }
                    </Button>
                </ThemeProvider>
            </div>
            <div className='filter-pane'>
                <Collapse in={!collapsed} sx={{'paddingLeft': '0.5rem'}}>
                    <SourcesCheckBoxesGroup states={props.sourceValues} handleChange={props.handleSource}></SourcesCheckBoxesGroup>
                    <FileTypeCheckBoxesGroup states={props.fileTypeValues} handleChange={props.handleFileType}></FileTypeCheckBoxesGroup>
                    <SortByRadioButtonsGroup value={props.sortByValue} handleClick={props.handleSortBy}></SortByRadioButtonsGroup>
                </Collapse>
            </div>
            
        </Box>
    );
}